<ion-app>
  <!-- Menu Profil -->
  <ion-menu contentId="menu-content" menuId="menu-content" side="start" type="overlay" (ionWillOpen)="menuOpened()">
    <ion-header>
      <ion-toolbar>
        <ion-title>NAbySY RH</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
     <!--  <ion-list>
        <ion-item>
          <ion-label>Trach</ion-label>
          <ion-icon slot="start" name="add"></ion-icon>
        </ion-item>
      </ion-list> -->
      <div class="menu-header-bg"></div>
      <div class="header-content">
        <img src={{this.photoUrl}} alt="">
        <ion-label>
          <h2>{{this.prenomEmploye}} {{this.nomEmploye}}</h2>
          <P>{{this.employeFonction}}</P>
        </ion-label>
      </div>
      <div class="action-button">
        <ion-button>
          <!-- <ion-icon slot="start" name="add"></ion-icon> Add Branch -->
          <ion-icon slot="start" name="add"></ion-icon> Rapport d'activité
        </ion-button>
      </div>

      <ion-list class="menu-items" lines="none">
        <ion-item routerLink="/home" (click)="closeMenu()">
          <!-- <ion-icon name="pie-chart-outline"></ion-icon> -->
          <ion-icon name="home-outline"></ion-icon> Accueil
        </ion-item>
        <ion-item routerLink="/direction" (click)="closeMenu()">
          <ion-icon name="business-outline"></ion-icon> Direction
        </ion-item>
        <ion-item routerLink="/personnel" (click)="closeMenu()">
          <!-- <ion-icon name="browsers-outline"></ion-icon> Post session -->
          <ion-icon name="person-outline"></ion-icon>Personnel
        </ion-item>
        <!-- <ion-item class="active"> -->
          <ion-item routerLink="/absence" (click)="closeMenu()">
          <!-- <ion-icon name="flash"></ion-icon> Live session -->
          <ion-icon name="calendar-outline"></ion-icon>Absence
        </ion-item>
        <ion-item routerLink="/affectation" (click)="closeMenu()">
          <!-- <ion-icon name="chatbubble-ellipses-outline"></ion-icon> Message -->
          <ion-icon name="send-outline"></ion-icon> Affectation
        </ion-item>
        <ion-item routerLink="/list-salaires" (click)="closeMenu()">
          <ion-icon name="cash-outline"></ion-icon>Salaire
        </ion-item>
        <ion-item routerLink="/paiement-salaire" (click)="closeMenu()">
          <ion-icon name="cash"></ion-icon>Paiement
        </ion-item>
        <ion-item routerLink="/prime" (click)="closeMenu()">
          <ion-icon name="logo-usd"></ion-icon>Primes
        </ion-item>
        <ion-item routerLink="/credit" (click)="closeMenu()">
          <ion-icon name="logo-usd"></ion-icon>Crédit
        </ion-item>
        <ion-item routerLink="/login" (click)="closeMenu(true)">
          <ion-icon name="exit-outline"></ion-icon>DECONNECTION
        </ion-item>

      </ion-list>
    </ion-content>
  </ion-menu>
  <!-- <ion-router-outlet id="menu-content"></ion-router-outlet> -->

<!-- Menu Direction -->
  <ion-menu contentId="menu-content" menuId="menu-content2" side="start" type="overlay">
    <ion-header>
      <ion-toolbar>
        <ion-title>Menu Direction</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item>
          <ion-label>Trash</ion-label>
          <ion-icon slot="start" name="trash"></ion-icon>
        </ion-item>
        <ion-item>
          <ion-label>Like</ion-label>
          <ion-icon slot="start" name="heart"></ion-icon>
        </ion-item>
        <ion-item routerLink="/personnel">
          <ion-label >Outbox</ion-label>
          <ion-icon slot="start" name="Add" ></ion-icon>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="menu-content"></ion-router-outlet>

</ion-app>
